import { useFeatureInLocale } from '@utils/hooks';
import { LOCALE_CODES } from '@constants';

const useIsLensExpansionTest = () => {
	/* Test will only run in the US */
	const isLensExpansionExpA = useFeatureInLocale('is-lens-expansion-exp-a', LOCALE_CODES.US);
	const isLensExpansionExpB = useFeatureInLocale('is-lens-expansion-exp-b', LOCALE_CODES.US);
	return {
		isLensExpansion: isLensExpansionExpA || isLensExpansionExpB,
		isLensExpansionExpA,
		isLensExpansionExpB,
	};
};

export default useIsLensExpansionTest;
