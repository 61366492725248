import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { LOCALE_CODES } from '@constants';

type Locale = (typeof LOCALE_CODES)[keyof typeof LOCALE_CODES] | Array<(typeof LOCALE_CODES)[keyof typeof LOCALE_CODES]>;

/**
 * This is an override of the useFeatureIsOn hook from GrowthBook. It checks if a feature is on and if the user's locale matches the locale passed in.
 * @param feature The feature name to check if it is on.
 * @param locale The locale to check if the user's locale matches.
 */
const useFeatureInLocale = (feature: string, locale: Locale) => {
	const isFeatureOn = useFeatureIsOn(feature);
	const { locale: userLocale } = useRouter();

	if (Array.isArray(locale)) {
		return locale.includes(userLocale) && isFeatureOn;
	}

	if (typeof locale === 'string') {
		return userLocale === locale && isFeatureOn;
	}

	return false;
};

export default useFeatureInLocale;
