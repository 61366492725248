/* eslint-disable max-lines */

import { MoneyV2 } from '@ts/shopify-storefront-api';
import { FRAME_COLORS } from '..';

export enum RX_TYPE {
	NON_RX = 'Non-RX',
	SINGLE_VISION = 'Single Vision',
	READERS = 'Readers',
	PROGRESSIVE = 'Progressive',
}

export type BASE_FRAME_RX_TYPE_VARIANTS = `${RX_TYPE}`;

export type BASE_FRAME_LENS_VARIANTS =
	| 'None'
	| 'No Add-ons'
	| 'Blue Light'
	| 'Light Responsive'
	| 'Premium Plus'
	| 'Blue Light + Light Responsive'
	| 'Blue Light + Premium Plus'
	| 'Light Responsive + Premium Plus'
	| 'Blue Light + Light Responsive + Premium Plus'
	| 'Sun - Black'
	| 'Sun - Brown'
	| 'Sun - Green'
	| 'Sun - Silver Reflective'
	| 'Sun - Pink Reflective'
	| 'Sun - Blue Reflective'
	| 'Sun - Green Reflective'
	| 'Basic'
	| 'Blue Light + Basic';

export enum BASE_FRAME_LENS_OPTIONS {
	BLUE_LIGHT = 'Blue Light',
	LIGHT_RESPONSIVE = 'Light Responsive',
	PREMIUM_PLUS = 'Premium Plus',
	CR39 = 'Basic',
	SUN_BLACK = 'Sun - Black',
	SUN_BROWN = 'Sun - Brown',
	SUN_GREEN = 'Sun - Green',
	SUN_SILVER_REFLECTIVE = 'Sun - Silver Reflective',
	SUN_PINK_REFLECTIVE = 'Sun - Pink Reflective',
	SUN_BLUE_REFLECTIVE = 'Sun - Blue Reflective',
	SUN_GREEN_REFLECTIVE = 'Sun - Green Reflective',
}

export const RX_LENS_OPTIONS: Array<BASE_FRAME_LENS_OPTIONS> = [
	BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT,
	BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE,
	BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS,
];
export const NON_RX_LENS_OPTIONS: Array<BASE_FRAME_LENS_OPTIONS> = [
	BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT,
	BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE,
];

export type BASE_FRAME_LENS_OPTION_VARIANTS = `${BASE_FRAME_LENS_OPTIONS}`;

/** Regex that matches everything in a string up to the second dash, "-".
 *  This is used to extract the color, shape and rx type from a Base Frame SKU
 */
export const BASE_REGEX = new RegExp(/([^-]+-[^-]+).*/);
export const BASE_NO_PRESCRIPTION_REGEX = new RegExp(/^\d{4}_\d{3}_ODF/);

export type BaseVariantPriceDictionary = Record<`${FRAME_COLORS} / ${RX_TYPE} / ${BASE_FRAME_LENS_VARIANTS}`, MoneyV2>;
