import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastContext } from '@context';
import { TOAST } from '@constants';
import { generateCartBundles, normalizeCart } from '@utils/normalizers';
import { NormalizedCart } from '@ts/cart';
import { Mutation } from '@ts/shopify-storefront-api';
import { cartLinesRemoveMutation, fetchStorefrontApi, throwCartErrors, useCartId } from '@services/shopify';
import { useIsLensExpansionTest } from '@utils/hooks';

type useCartRemoveMutation = {
	lineItemIds: string[];
	isOptimistic?: boolean;
};

const useCartRemove = (): UseMutationResult => {
	const queryClient = useQueryClient();
	const { showToast } = useToastContext();
	const { data: cartId } = useCartId();
	const { isLensExpansion } = useIsLensExpansionTest();

	return useMutation(
		['cart', 'remove', { cartId }],
		async ({ lineItemIds }: useCartRemoveMutation) => {
			const { cartLinesRemove }: Mutation = await fetchStorefrontApi(cartLinesRemoveMutation, {
				variables: { cartId, lineIds: lineItemIds },
			});
			const { userErrors, cart } = cartLinesRemove;

			throwCartErrors(userErrors);
			const normalizedCart = normalizeCart(cart, isLensExpansion);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
		},
		{
			onMutate: ({ lineItemIds, isOptimistic = true }) => {
				if (!isOptimistic) {
					return null;
				}
				queryClient.cancelQueries(['cart']);
				const cartSnapshot = queryClient.getQueryData(['cart', { cartId }]);

				queryClient.setQueryData<NormalizedCart>(['cart', { cartId }], previousCart => {
					const linesAfterRemoval = previousCart.lines.filter(line => !lineItemIds.includes(line.id));
					const updatedBundles = generateCartBundles(linesAfterRemoval, true);

					return {
						...previousCart,
						bundles: updatedBundles,
						lines: linesAfterRemoval,
						subtotal: linesAfterRemoval.reduce((res, cur) => res + cur.variant.price.amount * cur.quantity, 0),
					};
				});

				return { cartSnapshot };
			},
			onError: (error, props, context) => {
				console.error(`Error removing from cart ${cartId}: ${error}`);
				queryClient.setQueryData(['cart', { cartId }], context?.cartSnapshot);
				queryClient.invalidateQueries(['cart']);
				showToast(TOAST.LAST_ACTION_ERROR);
			},
			onSettled: () => {
				queryClient.invalidateQueries(['cart']);
			},
		}
	);
};

export { useCartRemove };
