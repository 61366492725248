import React from 'react';
import { Paragraph, Caption } from '@components';
import Tooltip from '../Tooltip';
import Flex from '../Flex';
import styles from './Checklist.module.scss';

export type ListProps = {
	description: string;
	checkmark: boolean;
	tooltip?: string;
}[];

type ChecklistProps = {
	title?: string;
	backgroundColor?: string;
	list: ListProps;
};

const Checklist = ({ title, backgroundColor = 'none', list }: ChecklistProps) => {
	return (
		<Flex column gap={4} className={styles.container} style={{ backgroundColor }}>
			{title && <Caption>{title}</Caption>}
			<ul>
				{list.map(({ description, checkmark, tooltip }, index) => (
					<li
						key={`panel-description-${index}`}
						className={`${styles['content-list-item']} ${checkmark ? styles['checkmark'] : styles['crossmark']}`}
					>
						<Paragraph>{description}</Paragraph>
						{tooltip && <Tooltip message={tooltip} />}
					</li>
				))}
			</ul>
		</Flex>
	);
};

export default Checklist;
