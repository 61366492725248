import { useEffect } from 'react';
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { NormalizedCart } from '@ts/cart';
import { normalizeCart } from '@utils/normalizers/normalize-cart';
import { cartQuery, fetchStorefrontApi, setCartId, useCartId, useCustomer } from '@services/shopify/index';
import { QueryRoot } from '@ts/shopify-storefront-api';
import { LOCALE_DICT } from '@constants';
import { useIsLensExpansionTest } from '@utils/hooks';

const useCart = (): UseQueryResult<NormalizedCart> => {
	const queryClient = useQueryClient();
	const Customer = useCustomer();
	const { isLensExpansion } = useIsLensExpansionTest();
	const { data: cartId } = useCartId();
	const { locale } = useRouter();
	const cart = useQuery<NormalizedCart>(
		['cart', { cartId }],
		async () => {
			if (!cartId) return null;
			const { cart }: QueryRoot = await fetchStorefrontApi(cartQuery, {
				variables: { id: cartId, country: LOCALE_DICT[locale].countryCode },
			});
			const normalizedCart = normalizeCart(cart, isLensExpansion);
			queryClient.setQueryData(['cart', { cartId }], normalizedCart);
			return normalizedCart;
		},
		{
			onError(error) {
				console.error(`Error fetching cart ${cartId}: ${error}`);
				setCartId(null);
				queryClient.invalidateQueries(['cart', 'id']);
			},
			placeholderData: null,
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			enabled: Boolean(cartId) && Customer.fetchStatus === 'idle',
			retry: false,
		}
	);

	useEffect(() => {
		cart.refetch();
	}, [cartId, isLensExpansion]);

	return cart;
};

export { useCart };
