import { Button, FavoriteIcon, Flex, LabelText, Modal, Zoom } from '@components';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { TOP_FRAMES_PATH } from '@constants';
import { trackSelectedProduct } from '@services/analytics/trackers';
import { useCartContext } from '@context';
import { ButtonGroupProps } from './ButtonGroup';

type StackedButtonProps = {
	collectionPath?: string;
	price: { amount: number; currencyCode: string };
	utmParam: string;
} & Pick<
	ButtonGroupProps,
	| 'secondaryAction'
	| 'secondaryActionData'
	| 'primaryAction'
	| 'variant'
	| 'isMobile'
	| 'dataTags'
	| 'product'
	| 'dispatch'
	| 'openMinicartOnPurchase'
	| 'parentCollectionHandle'
	| 'alreadyInCart'
	| 'linkToProduct'
	| 'label'
>;

const StackedButton = ({
	secondaryAction,
	secondaryActionData,
	primaryAction,
	variant,
	isMobile,
	dataTags,
	product,
	dispatch,
	openMinicartOnPurchase,
	parentCollectionHandle,
	collectionPath,
	alreadyInCart,
	price,
	linkToProduct,
	utmParam,
	label,
}: StackedButtonProps) => {
	const { handleCartAdd, isCartMutating } = useCartContext();

	return (
		<Flex column align='start' gap={2} style={{ margin: '0.8rem 0' }} fullWidth>
			{secondaryAction === 'custom' && secondaryActionData.customComponent}
			{secondaryAction === 'vto' && (
				<Modal.Trigger asChild>
					<Button
						color='white'
						fullWidth
						label='Virtual Try-On'
						data-tryon={product.name}
						onClick={() =>
							dispatch({
								type: 'product',
								option: {
									product,
									variantIdentifier: variant.option,
								},
							})
						}
					/>
				</Modal.Trigger>
			)}
			{secondaryAction === 'favorite' && (
				<Button color='white' fullWidth>
					<FavoriteIcon product={product} variant={variant} />
				</Button>
			)}
			{secondaryAction === 'zoom' && isMobile && (
				<Button color='white' fullWidth>
					<LabelText>Zoom</LabelText>
					<Zoom shape='square' {...dataTags.zoom} />
				</Button>
			)}
			{primaryAction === 'cart' && (
				<Button
					onClick={async () => {
						handleCartAdd(
							[
								{
									variant: { ...variant, collection: parentCollectionHandle ?? '' },
									customAttributes: [
										{
											key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH,
											value: `${TOP_FRAMES_PATH}/${parentCollectionHandle ?? collectionPath ?? ''}`,
										},
									],
								},
							],
							openMinicartOnPurchase
						);
					}}
					disabled={isCartMutating}
					fullWidth
					withPrice={!alreadyInCart}
					price={price.amount}
					currencyCode={price.currencyCode}
					{...(product?.name ? { 'data-add-to-cart': product.name } : { 'data-add-to-cart': true })}
					{...dataTags.button}
					{...(alreadyInCart && { disabled: true, label: 'In Cart!' })}
				/>
			)}
			{primaryAction === 'view' && (
				// The data-tag and tracking call are a little sneaky, but it's (currently) safe to assume that this will always be a Base Frame
				<Button
					href={`${linkToProduct}${utmParam}`}
					fullWidth
					label={label}
					data-design-your={product.name}
					onClick={() => trackSelectedProduct({ variant, path: collectionPath })}
					{...dataTags.button}
				/>
			)}
		</Flex>
	);
};

export default StackedButton;
