import { generateMetafieldQueries, variantMetafieldIdentifiers } from '@services/shopify/generate-metafield-queries';

const baseFrameVariantsQuery = /* GraphQL */ `
	query baseFrameVariants(
		$handle: String
        $country: CountryCode = US
	) @inContext(country: $country) {
		product(handle: $handle) {
			variants(first: 60) {
				edges {
					node{
						title
						price {
							amount
							currencyCode
						}
						${generateMetafieldQueries([variantMetafieldIdentifiers.find(i => i.key === 'cr39_ab_test')])}
					}
				}
			}	
		}
	}
`;
export default baseFrameVariantsQuery;
