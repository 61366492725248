import { useQuery } from '@tanstack/react-query';
import { getCustomer } from '@services/shopify';
import { getCustomerToken } from '../customer-token';

const useCustomer = () => {
	const customerAccessToken = getCustomerToken();

	return useQuery(
		['customer', customerAccessToken],
		async () => {
			const customer = await getCustomer(customerAccessToken);
			// As soon as customer data is made available to the app, sync info with heap
			if (customer && typeof window !== 'undefined' && window.heap) {
				window.heap.identify(customer.email);
			}
			return customer;
		},
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			onSuccess: data => {
				if (!data) return;
			},
		}
	);
};

export default useCustomer;
