import { useCallback, useMemo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';
import { PRODUCT_TYPES, BASE_FRAME_NAMES, NEW_BASE_FRAME_NAMES } from '@constants';
import { Caption, Chevron, Flex, HeartSparkle, HorizontalCard, Loading } from '@components';
import useRecommend from '@services/algolia/hooks/useRecommend';
import { getVariantByOption } from '@utils/shopify';
import { getBaseName } from '@utils/strings';
import { useIsMobile } from '@utils/hooks';
import { Bundle } from '@ts/cart';
import { NormalizedProduct } from '@ts/product';
import styles from './TopFrameRecommendation.module.scss';

type TopFrameRecommendationProps = {
	activeFrame: (typeof BASE_FRAME_NAMES)[number];
	bundles: Array<Bundle>;
	forceMobileStyle?: boolean;
	isMinicart?: boolean;
};

const TopFrameRecommendation = ({ activeFrame, bundles: rawBundles, isMinicart }: TopFrameRecommendationProps) => {
	const bundles = rawBundles.filter(bundle => !!bundle?.tops || !!bundle?.base);
	const isMobile = useIsMobile();
	const swiperRef = useRef(null);
	const nextElRef = useRef(null);
	const prevElRef = useRef(null);
	const hasBundleBaseFrame = bundles?.some(bundle => !!bundle?.base) ?? false;
	const lastTop = hasBundleBaseFrame ? null : bundles.findLast(bundle => !!bundle.tops)?.tops.slice(-1)[0];
	const allIdsInBundle = hasBundleBaseFrame
		? bundles.flatMap(bundle => bundle?.base?.frame?.variant?.id ?? [])
		: [lastTop?.variant?.id ?? ''];
	const [activeSlide, setActiveSlide] = useState(0);
	const MAX_RECS = 5;

	const getBundleFrameShape = useCallback(
		bundle => {
			const baseLineItem = bundle?.base;
			if (!!baseLineItem) return getBaseName(baseLineItem.frame.variant.name, false);
			if (!!bundle?.tops && hasBundleBaseFrame) return null; // This removes rec for tops with different base frames
			if (!!lastTop) return getBaseName(lastTop?.variant?.option, false);

			return activeFrame;
		},
		[activeFrame, hasBundleBaseFrame, lastTop]
	);

	const baseFrames = useMemo(
		() =>
			bundles.reduce(
				(a, c) => {
					const frameShape = getBundleFrameShape(c);
					a.push(frameShape);
					return a;
				},
				[] as Array<(typeof BASE_FRAME_NAMES)[number]>
			),
		[bundles, getBundleFrameShape]
	);

	const [{ recommendations, isLoading }] = useRecommend({
		objectIds: allIdsInBundle ?? [''],
		types: [PRODUCT_TYPES.TOP_FRAME],
		fallbackQuery: 'TOP_FRAME__CLASSIC',
		maxRecommendations: MAX_RECS,
		basesToRecommendFor: baseFrames,
	});

	const isMobileOrMinicart = isMobile || isMinicart;
	const bundlesToIterate: Bundle[] = [
		...(!hasBundleBaseFrame ? [bundles.findLast(bundle => !!bundle.tops)] : bundles),
	].reverse();
	let slidesPerView = isMobileOrMinicart ? 1.2 : 2.5;

	if (isLoading)
		return (
			<div className={styles['loading']}>
				<Loading />
			</div>
		);

	const chooseUpsellForBundle = (bundle: Bundle, recs: NormalizedProduct[]) => {
		const upsells = [] as Array<NormalizedProduct>;
		const topsInBundles = bundles.flatMap(bundle => bundle?.tops ?? []);
		for (let i = 0; i < recs.length; i++) {
			const rec = recs[i];
			if (!topsInBundles.some(t => t.variant.name === rec.name) && rec.variants[0].availableForSale) {
				upsells.push(rec);
			}
		}

		return upsells;
	};

	const cleanRecommendations: Array<{ upsell: NormalizedProduct; bundleKey: string; frameShape: `${NEW_BASE_FRAME_NAMES}` }> =
		[];
	bundlesToIterate?.forEach(bundle => {
		let counter = 0;
		chooseUpsellForBundle(bundle, recommendations ?? []).forEach(upsell => {
			const frameShape = getBundleFrameShape(bundle);
			const variant = getVariantByOption(upsell, frameShape);
			if (upsell && variant && counter < MAX_RECS) {
				counter += 1;
				cleanRecommendations.push({ upsell, bundleKey: bundle.key, frameShape });
			}
		});
	});

	if (cleanRecommendations.length < 3) {
		slidesPerView = 1.2;
	} else if (cleanRecommendations.length < 2) {
		slidesPerView = 1;
	}

	if (!cleanRecommendations.length) return null;

	return (
		<Flex
			column
			gap={3}
			maxWidth
			className={isMobileOrMinicart ? styles['recommendations-minicart'] : styles['recommendations']}
			data-testid='top-recommendations'
		>
			<Flex align='center' style={{ padding: '2.4rem 1.6rem 0 1.6rem' }}>
				<HeartSparkle width={30} height={35} style={{ paddingRight: '0.8rem' }} />
				<Caption className={styles.bundleTitle}>We Think You&#39;d Like</Caption>
			</Flex>
			<>
				<Swiper
					ref={swiperRef}
					direction='horizontal'
					spaceBetween={8}
					modules={[Navigation, FreeMode]}
					slidesPerView={slidesPerView}
					slidesPerGroup={1}
					style={{ width: '100%', paddingInline: '1.6rem', paddingBottom: '1.6rem' }}
					onInit={swiper => setActiveSlide(swiper.activeIndex)}
					onSlideChange={swiper => setActiveSlide(swiper.activeIndex)}
				>
					{bundlesToIterate?.map(bundle => {
						let counter = 0;
						return chooseUpsellForBundle(bundle, recommendations ?? []).map(upsell => {
							const frameShape = getBundleFrameShape(bundle);
							const variant = getVariantByOption(upsell, frameShape);
							if (upsell && variant && counter < MAX_RECS) {
								counter += 1;

								const dataTags = {
									button: {
										...(isMinicart && { 'data-add-to-cart-from-minicart': true }),
										...(!!upsell?.handle && variant.availableForSale
											? { 'data-upsell-added': upsell?.handle }
											: {}),
									},
									zoom: { 'data-tops-zoom': 'recommendations' },
									favorite: {},
								};

								return (
									<SwiperSlide tag='li' key={upsell?.handle} style={{ height: 'unset' }}>
										<Flex data-upsell-container column gap={4} maxWidth style={{ height: '100%' }}>
											<HorizontalCard
												key={upsell?.handle}
												aspectRatio='4/3'
												product={upsell}
												variant={variant}
												openMinicartOnPurchase={false}
												description={`for The ${frameShape}`}
												secondaryAction='zoom'
												isMobile={isMobile}
												bundleKey={upsell && bundle.key}
												contentType='vertical-micro'
												dataTags={dataTags}
												className={styles['recommendation-card']}
											/>
										</Flex>
									</SwiperSlide>
								);
							}
						});
					})}
				</Swiper>

				<Flex className={styles['navigation-container']} justify='end' gap={3}>
					<button
						ref={prevElRef}
						className={`${styles['button-prev']} ${activeSlide === 0 ? styles['disabled'] : ''}`}
						aria-label='previous slide'
						onClick={() => swiperRef && swiperRef.current && swiperRef.current.swiper.slidePrev()}
					>
						<Chevron direction='left' />
					</button>
					<button
						ref={nextElRef}
						className={`${styles['button-next']} ${activeSlide + (isMobile || isMinicart ? 1 : 2) >= swiperRef?.current?.swiper?.slides?.length ? styles['disabled'] : ''}`}
						aria-label='next slide'
						onClick={() => swiperRef && swiperRef.current && swiperRef.current.swiper.slideNext()}
					>
						<Chevron direction='right' />
					</button>
				</Flex>
			</>
		</Flex>
	);
};

export default TopFrameRecommendation;
