import { generateMetafieldQueries, variantMetafieldIdentifiers } from '@services/shopify/generate-metafield-queries';

const baseFrameVariantQuery = /* GraphQL */ `
	query baseFrameVariant($handle: String, $selectedOptions: [SelectedOptionInput!]!, $country: CountryCode = US)
	@inContext(country: $country) {
		product(handle: $handle) {
			variantBySelectedOptions(selectedOptions: $selectedOptions) {
				id
				price {
					amount
					currencyCode
				}
				compareAtPrice {
					amount
					currencyCode
				}
				selectedOptions {
					name
					value
				}
				title
				${generateMetafieldQueries([variantMetafieldIdentifiers.find(i => i.key === 'cr39_ab_test')])}
			}
		}
	}
`;
export default baseFrameVariantQuery;
