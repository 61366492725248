import { NormalizedCopyGroupFields, NormalizedProduct } from '@ts/product';
import {
	LENSES_PACKAGES,
	NON_PRESCRIPTION_COPY,
	PROGRESSIVES_COPY,
	READER_STRENGTHS,
	READERS_COPY,
	RX_TYPE,
	RX_TYPE_TEXT,
	SINGLE_VISION_COPY,
} from '@constants';
import { CopyGroupFields, LensInfo, LensOption } from '@ts/index';
import { BASE_FRAME_LENS_OPTIONS, NON_RX_LENS_OPTIONS, RX_LENS_OPTIONS, RX_TYPE as RX_TYPE_OPTION } from './constants/base-skus';

export const singleVision: LensOption = {
	id: RX_TYPE.SINGLE_VISION,
	copy: RX_TYPE_TEXT[RX_TYPE.SINGLE_VISION],
	description: SINGLE_VISION_COPY,
	price: { amount: 0, currencyCode: 'USD' },
	lenses: [],
	optionValue: RX_TYPE_OPTION.SINGLE_VISION,
};

const progressives: LensOption = {
	id: RX_TYPE.PROGRESSIVES,
	copy: RX_TYPE_TEXT[RX_TYPE.PROGRESSIVES],
	description: PROGRESSIVES_COPY,
	price: { amount: 199, currencyCode: 'USD' },
	lenses: [],
	optionValue: RX_TYPE_OPTION.PROGRESSIVE,
};

const readers: LensOption = {
	id: RX_TYPE.READERS,
	copy: RX_TYPE_TEXT[RX_TYPE.READERS],
	description: READERS_COPY,
	price: { amount: 0, currencyCode: 'USD' },
	lenses: [],
	options: READER_STRENGTHS,
	optionValue: RX_TYPE_OPTION.READERS,
};

const nonPrescription: LensOption = {
	id: RX_TYPE.NON_PRESCRIPTION,
	copy: RX_TYPE_TEXT[RX_TYPE.NON_PRESCRIPTION],
	description: NON_PRESCRIPTION_COPY,
	price: { amount: 0, currencyCode: 'USD' },
	lenses: [],
	optionValue: RX_TYPE_OPTION.NON_RX,
};

const getOptionValueForLensProduct = (product: NormalizedProduct) => {
	if (product.name.includes('Filtering')) {
		return BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT;
	}
	if (product.name.includes('Premium')) {
		return BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS;
	}
	if (product.name.includes('Responsive')) {
		return BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE;
	}
};

const getTagForLensProduct = (product: NormalizedProduct) => {
	if (product.name.includes('Filtering')) {
		return '#1 Best Seller';
	}
	if (product.name.includes('Premium')) {
		return 'Popular Upgrade';
	}
	if (product.name.includes('Responsive')) {
		return 'Most Convenient';
	}
};

export const getLensOptions = ({
	lensOptions,
	journey,
	demo,
	rxCopyGroup,
	lensCopyGroup,
	currencyCode = 'USD',
}: {
	lensOptions: NormalizedProduct[];
	journey: string;
	demo: string;
	rxCopyGroup: CopyGroupFields;
	lensCopyGroup: CopyGroupFields;
	currencyCode?: string;
}): LensOption[] => {
	// TODO: After A/B testing, we need to modify this logic that happens server side
	if (journey === 'blue-light') {
		return [
			{
				...nonPrescription,
			},
			{
				...singleVision,
				price: { amount: 49, currencyCode },
			},
			{
				...progressives,
				price: { amount: 248, currencyCode },
			},
			{
				...readers,
				price: { amount: 49, currencyCode },
			},
		];
	}

	if (journey === 'sunglasses') {
		return [
			{
				...nonPrescription,
				copy: rxCopyGroup.blocks.find(block => block.slug === `non-prescription-rx-option`).title,
				description: rxCopyGroup.blocks.find(block => block.slug === `non-prescription-rx-option`).description,
				lenses: [],
			},
			{
				...singleVision,
				copy: rxCopyGroup.blocks.find(block => block.slug === `single-vision-rx-option`).title,
				description: rxCopyGroup.blocks.find(block => block.slug === `single-vision-rx-option`).description,
				price: { amount: 99, currencyCode },
				lenses: [],
			},
			{
				...progressives,
				copy: rxCopyGroup.blocks.find(block => block.slug === `progressive-rx-option`).title,
				description: rxCopyGroup.blocks.find(block => block.slug === `progressive-rx-option`).description,
				price: { amount: 298, currencyCode },
				lenses: [],
			},
			{
				...readers,
				copy: rxCopyGroup.blocks.find(block => block.slug === `readers-rx-option`).title,
				description: rxCopyGroup.blocks.find(block => block.slug === `readers-rx-option`).description,
				price: { amount: 99, currencyCode },
				lenses: [],
			},
		];
	}

	// Regular eyeglasses flow
	const adjustedCopy: LensInfo[] = lensOptions.map(option => ({
		// ...option,
		// searching by title isn't super stable...
		name: lensCopyGroup.blocks.find(block => block.title === option.name).title,
		description: lensCopyGroup.blocks.find(block => block.title === option.name).description,
		optionValue: getOptionValueForLensProduct(option),
		handle: option.handle,
		tag: getTagForLensProduct(option),
	}));
	const removePremiumPlus = adjustedCopy?.filter(opt => opt.name !== 'Premium Plus');
	const nonRxLenses = removePremiumPlus;
	const priceCorrectedLenses = nonRxLenses.map(filtOpt => (filtOpt.name === 'Blue Light Filtering' ? filtOpt : filtOpt));
	return [
		{
			...singleVision,
			copy: rxCopyGroup.blocks.find(block => block.slug === `single-vision-rx-option`).title,
			description: rxCopyGroup.blocks.find(block => block.slug === `single-vision-rx-option`).description,
			lenses: demo === 'kids' ? removePremiumPlus : adjustedCopy,
		},
		{
			...progressives,
			copy: rxCopyGroup.blocks.find(block => block.slug === `progressive-rx-option`).title,
			description: rxCopyGroup.blocks.find(block => block.slug === `progressive-rx-option`).description,
			lenses: demo === 'kids' ? removePremiumPlus : adjustedCopy,
		},
		{
			...readers,
			copy: rxCopyGroup.blocks.find(block => block.slug === `readers-rx-option`).title,
			description: rxCopyGroup.blocks.find(block => block.slug === `readers-rx-option`).description,
			lenses: removePremiumPlus,
		},
		{
			...nonPrescription,
			copy: rxCopyGroup.blocks.find(block => block.slug === `non-prescription-rx-option`).title,
			description: rxCopyGroup.blocks.find(block => block.slug === `non-prescription-rx-option`).description,
			lenses: priceCorrectedLenses,
		},
	];
};

export const getOptionsForRxType = (options: LensOption[], rxType: RX_TYPE_OPTION) => {
	if (rxType === RX_TYPE_OPTION.READERS || rxType === RX_TYPE_OPTION.NON_RX) {
		return options.filter(option => ({
			...option,
			lenses: option.lenses.filter(lens => NON_RX_LENS_OPTIONS.includes(lens.optionValue)),
		}));
	}

	return options.filter(option => ({
		...option,
		lenses: option.lenses.filter(lens => RX_LENS_OPTIONS.includes(lens.optionValue)),
	}));
};

export const parseLensOptionToLensPackage = (lensOption: BASE_FRAME_LENS_OPTIONS): LENSES_PACKAGES => {
	switch (lensOption) {
		case BASE_FRAME_LENS_OPTIONS.CR39:
			return LENSES_PACKAGES.BASIC;
		case BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS:
			return LENSES_PACKAGES.PREMIUM;
		default:
			return LENSES_PACKAGES.STANDARD;
	}
};

export const findLensPackageInLensOptions = (lensOptions: BASE_FRAME_LENS_OPTIONS[]): LENSES_PACKAGES => {
	return parseLensOptionToLensPackage(
		lensOptions.find(lens => lens === BASE_FRAME_LENS_OPTIONS.CR39 || lens === BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS)
	);
};
